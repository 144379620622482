<script setup>
	import authService from '@/services/authservice'
</script>
<template>
    <div>
        <p>Sign-in in progress</p>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        async created() {

            try {
           
                var result = await authService.mgr.signinRedirectCallback();
                console.log('result');
                var returnToUrl = '/';
                if (result.state !== undefined) { returnToUrl = result.state;}
                this.$router.push({ path: returnToUrl });
            } catch (e) {
				authService.mgr.clearStaleState();
				//localStorage.clear();
                console.log('error');
                console.log(e);
                this.$router.push({ name: 'Unauthorized' });
            }
        }
    }
</script>