
<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
export default {
  data(){
    return{
       showAddWizard:false,
       showAddBookMark:false,
       showNofication:false,
       wizardType:'',
       controllerName:null,
       actionName:null,
       isShowMenuHeader:true
       
    }
  },
  created(){
    console.log('userinfo', this.GetUserInfo.PageSize);
    var self = this;
    var pathName = window.location.pathname.split("/");
    self.controllerName= pathName[1].toLowerCase();
    self.actionName= pathName[2].toLowerCase();
    if((self.actionName=='fullmenu') || ((self.controllerName=='user' || self.controllerName=='account') && (self.actionName=='dashboard' || self.actionName=='home'))){
      self.isShowMenuHeader=false;
    }
   
  },
  updated(){
    var self = this;
    var pathName = window.location.pathname.split("/");
    self.controllerName= pathName[1].toLowerCase();
    self.actionName= pathName[2].toLowerCase();
    if((self.actionName=='fullmenu') || ((self.controllerName=='user' || self.controllerName=='account') && (self.actionName=='dashboard' || self.actionName=='home'))){
      self.isShowMenuHeader=false;
    }
    else{
      self.isShowMenuHeader=true;
    }
  },
  setup(){     
    const store = useloggedUserInfo();
    const modulestore = moduleStore()
		const { GetUserInfo } = storeToRefs(store)
    const { GetModuleId } = storeToRefs(modulestore)
		return {GetUserInfo,GetModuleId }    
  }, 
}
</script>
<template>
  <section>
    <ModuleHeader v-if="(GetUserInfo != null && isShowMenuHeader)" :modulemasterid="String(GetModuleId)"></ModuleHeader>   
    <section id="main-content" class="main-content">
            <div class="container-fluid">
                <div class="row" style="min-height:100vh;">
                    <div class="col-12 mt-2">
                      <router-view/>
                    </div>
                </div>
            </div>
            <tooltip  :width="250"/>
        </section>
  </section>       
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}

</style>
