import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;
const docstorage= process.env.VUE_APP_DOCSTORAGE_API;
const dstorageAPI = `${docstorage}`;
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
  });
  const docstrage =axios.create({
    baseURL:dstorageAPI,
});
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
docstrage.interceptors.request.use(
    (config) => {
        if(useloggedUserInfo().GetUserInfo!=null){
            const token = useloggedUserInfo().GetUserInfo.Token;
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
                config.headers["content-type"] = "application/json;charset=UTF-8"
                config.headers['Access-Control-Allow-Origin'] = '*';
            }
            return config;
        }
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instanceCommon,docstrage};
