import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/components/Dashboard/Dashboard.vue"),
        meta: { title: 'Dashboard' }
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: '/Account/LogOff',
        name: 'logout',
        component: Unauthorized
    },        
    {
        path: "/File/DashBoard",
        name: "DashBoard",
        component: () => import("@/views/UI/Dashboard.vue"),
        meta: { title: 'Dashboard' ,checkPrivilege:false,
        breadcrumb:[
            {name:'Dashboard'}
        ]}
    },
    {
        path: "/File/AdditionalStorage",
        name: "AdditionalStorage",
        component: () => import("@/views/UI/Additional Storage/AdditionalStorage.vue"),
        meta: { title: 'Additional Storage' ,checkPrivilege:false,
        breadcrumb:[
            {name:'Default Storage'}
        ]}
    },
    {
        path: "/files/onedrivetoken",
        name: "onedrivetoken",
        component: () => import("@/components/Common/IntegrateCloudStorage.vue"),
        meta: { title: 'Dashboard' ,checkPrivilege:false,
        breadcrumb:[
            {name:'Dashboard'}
        ]}
    },
    {
        path: "/files/GoogleDrive",
        name: "GoogleDrive",
        component: () => import("@/components/Common/IntegrateGoogleDrive.vue"),
        meta: { title: 'Dashboard' ,checkPrivilege:false,
        breadcrumb:[
            {name:'Dashboard'}
        ]}
    },
    {
        path: "/ViewFileDetails/:FileId/:docCode/:FolderId/:fileType/:Permission/:CommonParentName/:RefCode",
        name: "ViewFileDetails",
        component: () => import("@/views/UI/ViewFileDetails.vue"),
        meta: { title: 'File Detail',
        breadcrumb:[
            {name:'Dashboard', link:"/File/Dashboard"},
            {name:'File Detail'}
        ],
        checkPrivilege:true,
        controllerName:'File',
        actionName:'ViewFileDetails'}
    },
    {
        path: "/RecyleBin",
        name: "RecyleBin",
        component: () => import("@/views/UI/RecyleBinListing.vue"),
        meta: { title: 'Recycle Bin Listing' }
    },
    {
        path: "/Storage",
        name: "Storage",
        component: () => import("@/views/UI/StorageForRetention.vue"),
        meta: { title: 'File Detail' }
    },  
    {   
        path: "/MyDashboard",
        name: "MyDashboard",
        component: () => import("@/views/UI/MyDashboard.vue"),
        // meta: { title: 'File Detail' }
    },
    {   
        path: "/ViewFolderDetails/:FolderId/:docCode/:ParentId/:Permission/:CommonParentName/:RefCode",
        name: "ViewFolderDetails",
        component: () => import("@/views/UI/ViewFolderDetails.vue"),
         meta: { title: 'Folder Detail',
         breadcrumb:[
             {name:'Dashboard', link:"/File/Dashboard"},
             {name:'Folder Detail'}
         ],
         checkPrivilege:true,
         controllerName:'File',
         actionName:'ViewFolderDetails'}
    },
    {   
        path: "/AdvancedSetup",
        name: "AdvancedSetup",
        component: () => import("@/views/UI/AdvancedSetup.vue"),
         meta: { title: 'Advanced Setup',
         breadcrumb:[
            {name:'Advanced Setup'}
        ]}
        
    },
    {   
        path: "/UserStorageReport/Index",
        name: "StorageReport",
        component: () => import("@/views/UI/StorageReport.vue"),
        meta: { title: 'Storage',
        breadcrumb:[
           {name:'Manage Storage'}
       ]}
    },
    {
        path: "/UserStorage/Manage",
        name: "UserStorage",
        component: () => import("@/views/UI/UserStorage.vue"),
        meta: { title: 'User Storage Detail',
        breadcrumb:[
           {name:'Manage User Storage'}
       ]}
    },

     {
        path: "/UserStorage/ManageClient",
        name: "ClientStorage",
        component: () => import("@/views/UI/ClientStorage.vue"),
        meta: { title: 'Client Storage Detail',
        breadcrumb:[
           {name:'Manage Client Storage'}
       ]}
    },
    {
        path: "/ManageTags",
        name: "ManageTags",
        component: () => import("@/views/UI/ManageTags.vue"),
        meta: { title: 'Tags Detail' }
    },
    {
        path: "/DownloadPublicFile",
        name: "ManageTags",
        component: () => import("@/views/UI/DownloadPublicFile.vue"),
        meta: { title: 'Preview and Download Files' }
    },
    {
        path: "/DownloadPublicFolder",
        name: "DownloadPublicFolder",
        component: () => import("@/views/UI/DownloadPublicFolder.vue"),
        meta: { title: 'Preview and Download Files' }
    }

]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
 
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    if(to.path != '/DownloadPublicFile' || to.path != '/DownloadPublicFolder'){
        document.getElementById("divProgress1").style.display = 'none';
        document.getElementById("talygenfooter").style.display = 'block';
    }
   
    if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback' || to.path == '/DownloadPublicFile' || to.path == '/DownloadPublicFolder') {
        next()
    }
    else {
        if (to.path == '/logout') {
            authService.logOut();
        } else {
            const ustore = useloggedUserInfo();
            let app = ustore.GetUserInfo;
            if (app) {
                let isCheckPrivilege = true;
                if(to.meta.hasOwnProperty('checkPrivilege'))
                {
                    isCheckPrivilege = to.meta.checkPrivilege;
                }
                if(isCheckPrivilege) {
                    let controllerName = "", actionName = "";
                    if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                        controllerName = to.meta.controllerName;
                        actionName = to.meta.actionName;
                    }
                    else{
                        let pathArray = to.path.split("/");
                        if(pathArray.length > 2){
                            controllerName = pathArray[1];
                            actionName = pathArray[2];
                        } else{
                            controllerName = pathArray[0];
                            actionName = pathArray[1];
                        }
                    }
                    if(controllerName != "" && actionName != "") {
                        DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                            if (value.data)
                                next()
                            else
                                //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                                next('/unauthorized');
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
            else 
            {
               

                authService.authenticate(to.path).then(() => {


                    console.log('authenticating a protected url:' + to.path);
                    next();
                });
            }
        }
    }
});
export default router

