import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?"+params);
    }
  FolderFullTreeView() {
    return  http.docstrage.get("/FolderFullTreeView");
  }
  MoveFolderTreeView(params) {
    return  http.docstrage.get("/MoveFolderTreeView?folderJsonData="+params);
  }
  GetDashboardData(params){
    return   http.instanceCommon.get("/common/GetDashboardData?"+params);
  }
  GetFolderList(params) { 
    return   http.docstrage.post("/GetFolderList?"+params);
  }
  GetUserSharedFolderFileList(params) { 
    return   http.docstrage.get("/GetUserSharedFolderFileList?"+params);
  }
  ManageFolderPin_UpPin(params) {
    return  http.docstrage.post("/ManageFolderPin_UpPin?postString="+ params);
  }
  SaveUnPin(params) { 
    return  http.docstrage.post("/SaveUnPin?postString="+ params);
  }
ManageUserGroup(params){
  return http.docstrage.post("/ManageUserGroup?postJsonString="+params); 
 }
 async GetUserGroupListing(params) {
  return await http.docstrage.get("/GetSharedDocStorageUserList?"+params);
}
async GetDDLDatas(params) {
  return await http.instanceCommon.get("/Common/GetDDLData?"+ params);
}
GetUserGroupDetail(params) { 
  return  http.docstrage.get("/GetUserGroupDetail?"+ params);
}
GetSharedDocStorageParentUserInfo(params) { 
  return  http.docstrage.get("/GetSharedDocStorageParentUserInfo?"+ params);
}

  FolderBreadCrumbs(params) {
    return  http.docstrage.get("/GetFolderBreadCrumb?" + params);
  }
  ManageFolder(params) { 
    
    return  http.docstrage.post("/ManageFolder?jsonData="+ params)
  }
  docShare(params) { 
    
    return  http.docstrage.post("/docShare?jsonData="+ params)
  }
  GetStorageFolderDetails(params) { 
    return  http.docstrage.get("/GetStorageFolderDetail?"+ params);
  }
  GetStorageFileSummary(params) {
    return  http.docstrage.get("/GetStorageFileSummary?"+params);
  }
  GetPublicallyStorageDoc(params) {
    return  http.docstrage.get("/GetPublicallyStorageDoc?"+params,{responseType:"blob"});
  }
  GetPublicallyStorageDocs(params) {
    return  http.docstrage.get("/GetPublicallyStorageDocs?"+params,{responseType:"blob"});
  }
  SharedUsersParentList(params) {
    return http.docstrage.get("/docSharedUsersParentList?", {params});
}
SharedUsersParentListByParentId(params) {
  return http.docstrage.get("/docSharedUsersListByParentId?", {params});
}
ManageSharedDocStorage(params) {
  return http.docstrage.post("/SharedDocStorage?"+ params);
}
MoveFileFolder(params) {
  return  http.docstrage.post("/MoveFileFolder?"+params);
}
async GetUserSharedListing(params) {
  return await http.docstrage.get("/GetDocStorageSharedUsersListByParentAndSource?"+params);
}
DeleteParentsUsersSharedFilesFoldersBySource(params) {
  return http.docstrage.post("/DeleteParentsUsersSharedFilesFoldersBySource?"+params);
}
GetRecycleBinListing(params) {
  return   http.docstrage.get("/GetRecycleBinListing?"+params);
}
GetFileDetail(params) { 
  return   http.docstrage.post("/GetFileDetail?"+params);
}
GetSharedFolderTree(){
  return   http.docstrage.get("/GetSharedFolderTree");
}
GetChildFolderList(params){
  return   http.docstrage.get("/GetChildFolderList?"+params);
}
RestoreItemsFromRecycleBinList(params){
  
  return   http.docstrage.get("/RestoreItemsFromRecycleBinList?"+params);
}

UpdateDocStorageConfigData(params){
  return   http.docstrage.get("/UpdateDocStorageConfigData?jsonData="+ params);
}

GetDashboardWidgetData(params){
  return http.docstrage.get("/GetDashboardWidgetData?"+ params);
}
ManageDashboardWidget(params){
  return http.docstrage.get("/ManageDashboardWidget?"+ params);
}
GetRecentlyAndPinnedFolderTreeData(params){
  return   http.docstrage.get("/GetRecentlyAndPinnedFolderTreeData");
}
GetOrganizationFolderTreeData(params){
  return   http.docstrage.post("/GetOrganizationFolderTreeData");
}
GetOrganizationFolderList(params){
  return   http.docstrage.post("/GetOrganizationFolderList?"+params);
}
GetRecentlyAddedAndPinnedFolderListing(params){
  return   http.docstrage.get("/GetRecentlyAddedAndPinnedFolderListing?"+params);
}
GetParentFolderBreadCrumb(params){
  return   http.docstrage.get("/GetParentFolderBreadCrumb?folderId=" + params);
}
GetAllFileVersionExcludeLatest(params) {
  return  http.docstrage.post("/GetAllFileVersionExcludeLatest?fileId="+ params);
}
UploadDoc(params) {
  return  http.docstrage.post("/UploadDoc?"+ params);
}
DownloadFile(params){
  return http.docstrage.get("/DownloadFileSingle?" + params,{responseType:"blob"});
}
DownloadPublicFileSingle(params){
  return http.docstrage.get("/DownloadPublicFileSingle?" + params,{responseType:"blob"});
}
DeleteStorageFilePermanently(params){ 
  return   http.docstrage.post("/DeleteStorageDoc_V1", params);
}
DownloadPublic(params){
  return http.docstrage.get("/GetPublicallyData");
}
GetConfigDataValue(params){
  return http.docstrage.get("/GetConfigDataValue?"+params);
}
GenerateDocPublicLink(params){
  return http.docstrage.get("/GenerateDocPublicLink?"+params);
}
GetStorageFolderDetail(params) {
  return  http.docstrage.get("/GetStorageFolderDetail?"+params);
}
CloneFilesInFolder(params){
  return http.docstrage.post("/CloneFilesInFolder?postString="+ params);
}
DownloadDocFolder(params) {
  return http.docstrage.get("/DownloadDocFolder?folderId=" + params,{responseType:"blob"});
}
GetStorageReport(){
  return http.docstrage.get("/GetStorageReport");
}
SaveContainerDetails(params){
  return http.docstrage.post("/SaveContainerDetails?"+params);
}
async GetUserStorageDetail(params) {
  return await http.docstrage.get("/GetUserStorageDetail?"+params);
}
 DeleteTempDownloadDocFolder(params) {
  debugger
  return  http.docstrage.get("/DeleteTempDownloadDocFolder?"+params);
}
ManageDocUserStorageSettingData(params) {
  return  http.docstrage.post("/ManageDocUserStorageSettingData",params);
}
GetStorageSourceList() {
  return  http.docstrage.get("/GetStorageSourceList");
}
SaveDocStorageSourceConfigData(params) {
  return  http.docstrage.post("/SaveDocStorageSourceConfigData?"+params);
}
GetCompanyStorageSourceConfig() {
  return  http.docstrage.get("/GetCompanyStorageSourceConfig");
}
GetStorageSetupStatusDetail(){
  return  http.docstrage.get("/GetStorageSetupStatusDetail");
}
GetTagListByModule(params){
  
  return  http.docstrage.get("/GetTagListByModule?"+params);
}
UpdateCommonStatus(params){
  return  http.docstrage.post("/UpdateCommonStatus?"+params);
}
GetFileType(params){
  
  return http.docstrage.get("/GetFileTypes?"+params);
}
GetTimeZone(params){
  return http.docstrage.get("/GetTimeZone",params );
}
UploadFile2(params){
  return http.docstrage.post("/UploadFile2",params );
}
GetCompanyContainerDetails(){
  return http.docstrage.get("/GetCompanyContainerDetails?" );
}
StartCopyMoveOpr(params){
  return http.docstrage.post("/StartCopyMoveOpr?"+params);
}
CheckMovedFolderIdEqualToDestFolderId(params) {
  return  http.docstrage.post("/CheckMovedFolderIdEqualToDestFolderId?"+params);
}
GetUserStorage(params){
  return http.docstrage.get("/GetUserStorage");
}
TestCredential(params) {
  debugger
  return  http.docstrage.post("/TestCredential?"+params);
}
GetPubLicLinkFolderList(params) { 
  return   http.docstrage.post("/GetPubLicLinkFolderList?"+params);
}
GetPublicLinkFolderBreadCrumb(params) {
  return  http.docstrage.get("/GetPublicLinkFolderBreadCrumb?folderId=" + params);
}
GetStorageRegionList(params) {
  return  http.docstrage.get("/GetStorageRegionList?"+ params);
}
DeleteTag(params) {
  return  http.docstrage.post("/DeleteTag?tagId="+params);
}
CommonDelete(params) {  
  return http.instanceCommon.post("/common/CommonDelete", {postString: params});
}
GetConfigurationData(params){  
  return http.instanceCommon.get("/Common/GetModuleKeys?"+params );
}
GetBlobUrl(params){  
  return http.instanceCommon.get("/Common/GetBlobUrl?"+params );
}
GetCurrency(params){
  return http.instanceCommon.get("/Common/GetCurrency?"+params );
}
SaveTag(params) {
  return http.instanceCommon.post("/Tag/SaveTag", { postString: params })
}
gettalygentagdetail(params){
  return http.instanceCommon.get("/Common/gettalygentagdetail?"+params );
}
async GetDDLData(params) {
  return await http.instanceCommon.get("/Common/GetDDLData?", {params} );
}
ManageTalygenTags(params) {
  return  http.instanceCommon.post("/common/ManageTalygenTags?postJsonString="+ params);
}
async GetModuleMasterIdByModuleName(params){ 
  return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?"+params);
}
async GetConfigurationValueForCompanyByKey(param){
  return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?"+param);
}
async GetHideContextMenuConfig(){
  return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
}
async SaveHideContextMenuConfig(param){
  return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?"+param);
}
async CheckPrivilege(params){
  return await http.instanceCommon.get("/Common/HasPermission?" + params);
}
async GetAuthorizationUrl(params){
  return await http.docstrage.get("/OneDrive/GetAuthorizationUrl?" + params);
}
async GetAccessTokenWithPKCE(params){
  return await http.docstrage.post("/OneDrive/GetAccessTokenWithPKCE?" + params);
}
async GetOneDriveStorage(params){
  return await http.docstrage.post("/OneDrive/GetOneDriveStorage?" + params);
}
async InsertNotification(param) {
  return await http.instanceCommon.post("/Notification/InsertNotification", { postString: param });
}
async GetStorageListing(param) {
  return await http.docstrage.get("/OneDrive/GetStorageListing?" + param);
}
async CopyMoveFileToOneDrive(params){
  return await http.docstrage.post("/OneDrive/CopyMoveFileToOneDrive?"+params);
}
async UpdateFileFolderInOneDrive(params){
  return await http.docstrage.post("/OneDrive/UpdateFileFolderInOneDrive?"+params);
}
async GetAdditionalStorage(params){
  return await http.docstrage.get("/GetAdditionalStorage?" + params);
}
async DownloadAdditionalStorageFile(params){
  return await http.docstrage.get("/OneDrive/DownloadFile?" + params);
}
async DeleteFileFromOneDrive(params){
  return await http.docstrage.post("/OneDrive/DeleteFileFromOneDrive?"+params);
}
async DeleteAdditionalStorage(params){
  return await http.docstrage.post("/DeleteAdditionalStorage?" + params);
}
async ImportOneDriveFile(params){
  return await http.docstrage.post("/OneDrive/ImportFile?" + params);
}
async MoveFileFolderAdditionalToDefault(params){
  return await http.docstrage.post("/MoveFileFolderAdditionalToDefault?" + params);
}
async MoveFolderAdditionalToDefault(params){
  return await http.docstrage.post("/MoveFolderAdditionalToDefault?" + params);
}
DownloadFileFolder(params){
  return http.docstrage.get("OneDrive/DownloadFileFolder?"+params);
}
WebHookSubscription(params){
  return http.docstrage.post("OneDrive/WebHookSubscription?"+params);
}
async NetworkSharedDriveAccess(params){
  return await http.docstrage.get("NetworkSharedDrive/GetFileList?" + params);
}
async GetNetworkFileList(params){
  return await http.docstrage.get("NetworkSharedDrive/GetNetworkFileList?" + params);
}
async SaveNetworkDriveCredential(params){
  return await http.docstrage.get("NetworkSharedDrive/SaveNetworkDriveCredential?" + params);
}
async DownloadNetworkSharedFile(params){

  return await http.docstrage.get("NetworkSharedDrive/DownloadNetworkSharedFile?" + params,{responseType:"blob"});
}
async NetworkDriveFileUpload(params){
  return await http.docstrage.post("NetworkSharedDrive/NetworkDriveFileUpload?" + params);
}
ManageFolderNetworkDrive(params) { 
  return  http.docstrage.post("NetworkSharedDrive/ManageFolderNetworkDrive?"+ params)
}
DeleteSharedDriveFolder(params) { 
  return  http.docstrage.post("NetworkSharedDrive/DeleteSharedDriveFolder?"+ params)
}
async GetAccessToken(params){
  return await http.docstrage.get("/OneDrive/GetAccessToken?" + params);
}
async GetAdditionalOrganizationSpaceId(params){
  return await http.docstrage.get("/GetAdditionalOrganizationSpaceId?" + params);
}
async SharedDriveMoveFolderFiles(params){
  return await http.docstrage.post("NetworkSharedDrive/SharedDriveMoveFolderFiles?" + params);
}
async MoveNetworkDriveFolderAndFile(params){
  return await http.docstrage.post("/MoveNetworkDriveFolderAndFile?" + params);
}
}
export default new DataService();
